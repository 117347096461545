<template>
  <div>
    <AppToasts />

    <AppHeader />

    <slot />

    <ModalsContainer />
  </div>
</template>

<script lang="ts" setup>
import { ModalsContainer } from 'vue-final-modal';

useHead({
  templateParams: {
    site: {
      name: 'Axos Kundeportal',
    },
    separator: '·',
    subPage: null,
  },
  titleTemplate: '%subPage %separator %s %separator %site.name',
  meta: [{ name: 'theme-color', content: '#0059ff' }],
  link: [
    { rel: 'apple-touch-icon', sizes: '192x192', href: '/touch-icon.png' },
  ],
  bodyAttrs: {
    class: 'text-slate-800 bg-slate-75 lg:bg-white',
  },
  htmlAttrs: {
    class: 'overflow-x-hidden',
  },
});
</script>
