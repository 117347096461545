import type { ExternalPortalOrganisation } from '~/codegen/axos-client';

export default function () {
  const organisation = useState<ExternalPortalOrganisation | null>(
    'organisation',
    () => null,
  );

  return {
    organisation,
  };
}
