export default defineNuxtRouteMiddleware(async (to, from) => {
  const config = useRuntimeConfig();

  if (to.path !== '/' && to.path !== '/me' && to.path !== '/invalid-token') {
    return navigateTo('/');
  }

  if (to.query.token) {
    const { data, error } = await useFetch('/external-portal-login', {
      baseURL: config.public.BFF_URL,
      method: 'POST',
      body: {
        token: to.query.token,
      },
      credentials: 'include',
    });

    if (data.value?.success) {
      return navigateTo({
        ...to,
        query: {
          ...to.query,
          token: undefined,
        },
      });
    } else if (data.value?.errorCode === 'TOKEN_EXPIRED') {
      return navigateTo('/invalid-token');
    }

    return navigateTo('/');
  }
});
