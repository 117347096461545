import {
  createMultiStepPlugin,
  createAutoAnimatePlugin,
} from '@formkit/addons';
import { FormKitNode } from '@formkit/core';
import { nb, en } from '@formkit/i18n';
import { applicationIcons, genesisIcons } from '@formkit/icons';
import { createProPlugin, inputs } from '@formkit/pro';
import { defineFormKitConfig, createInput } from '@formkit/vue';
import { rootClasses } from './formkit.theme';
import Datepicker from './components/Input/InputDatepicker.vue';
import InputAutocomplete from './components/Input/InputAutocomplete.vue';

const legends = ['checkbox_multi', 'radio_multi', 'repeater', 'transferlist'];

function addAsteriskPlugin(node: any) {
  if (
    ['button', 'submit', 'hidden', 'group', 'list', 'meta'].includes(
      node.props.type,
    )
  )
    return;

  node.on('created', () => {
    const legendOrLabel = legends.includes(
      `${node.props.type}${node.props.options ? '_multi' : ''}`,
    )
      ? 'legend'
      : 'label';

    if (node.props.definition.schemaMemoKey) {
      node.props.definition.schemaMemoKey += `${
        node.props.options ? '_multi' : ''
      }_add_asterisk`;
    }

    const schemaFn = node.props.definition.schema;
    node.props.definition.schema = (sectionsSchema = {}) => {
      sectionsSchema[legendOrLabel] = {
        children: [
          '$label',
          {
            $el: 'span',
            if: '$state.required',
            attrs: {
              class: 'text-slate-400 text-xs font-normal',
            },
            children: [' *'],
          },
        ],
      };

      return schemaFn(sectionsSchema);
    };
  });
}

function preventAutoFill(node: any) {
  if (node.type !== 'input') return;

  node.on('created', () => {
    if (node.props.definition.schemaMemoKey) {
      node.props.definition.schemaMemoKey += `${
        node.props.options ? '_multi' : ''
      }_prevent_autofill`;
    }

    const schemaFn = node.props.definition.schema;
    node.props.definition.schema = (sectionsSchema = {}) => {
      sectionsSchema.input = {
        attrs: {
          'data-lpignore': true,
          'data-1p-ignore': true,
          autocomplete: 'off',
        },
      };

      return schemaFn(sectionsSchema);
    };
  });
}

function axosNodeDefaults(node: FormKitNode) {
  if (node.props?.type === 'datepicker') {
    node.props.weekStart = 1;
    node.props.format = 'DD.MM.YYYY';

    if (node.props.valueFormat === undefined) {
      if (node.props.timePicker) {
        node.props.valueFormat = `yyyy-MM-dd'T'HH:mm`;
      } else {
        node.props.valueFormat = 'yyyy-MM-dd';
      }
    }

    node.props.calendarIcon = 'calendar';

    // Backend will use `null` for empty dates
    node.hook.input((value, next) => {
      if (value === undefined) return next(null);
      return next(value);
    });
  }

  if (node.props?.type === 'currency') {
    node.hook.input((value, next) => {
      if (value === undefined) return next(null);
      return next(value);
    });
  }

  if (
    node.props?.type === 'autocomplete' ||
    node.props?.type === 'dropdown' ||
    node.props?.type === 'taglist'
  ) {
    node.props.openOnClick = true;
    node.props.openOnFocus = true;
    node.props.clearSearchOnOpen = true;

    if (
      node.props?.selectionAppearance !== 'tags' &&
      !node.props?.attrs?.['disable-selection-removable']
    ) {
      node.props.selectionRemovable = true;
    }

    node.props.emptyMessage = 'Ingen treff';
  }

  if (node.props?.type === 'dropdown' || node.props?.type === 'autocomplete') {
    node.props.selectIcon = 'chevron-up-down';

    if (node.props.loadOnCreated === undefined) {
      node.props.loadOnCreated = true;
    }
  }

  // Autofocus
  if (
    node.type === 'input' &&
    node.props.id &&
    node.props?.attrs?.autofocus !== undefined
  ) {
    // Special handling for async dropdowns
    if (
      (node.props?.type === 'autocomplete' ||
        node.props?.type === 'dropdown') &&
      !!node.props.optionsLoader
    ) {
      node.on('mounted', () => {
        function findParentWithClass(
          element: HTMLElement | null,
          className: string,
        ): HTMLElement | null {
          if (!element) return null;
          if (element.classList.contains(className)) return element;
          return findParentWithClass(element.parentElement, className);
        }

        const el = document.getElementById(node.props.id as string);
        if (el) {
          const hasSlideoverParent = findParentWithClass(el, 'ax-slideover');

          // Because slideover has an animation, we need to wait a bit
          setTimeout(
            () => {
              node.context?.handlers?.focus?.();
            },
            hasSlideoverParent ? 400 : 0,
          );
        }
      });
    } else {
      // Normal inputs
      node.on('mounted', () => {
        const el = document.getElementById(node.props.id as string);
        if (el && el.hasAttribute('autofocus')) {
          el.focus();
        }

        // Handle custom datepicker
        const datepickerEl = document.getElementById(
          ('dp-input-' + node.props.id) as string,
        );

        if (datepickerEl && el && el.hasAttribute('autofocus')) {
          datepickerEl.focus();
        }
      });
    }
  }

  // Fix errors in Multistep
  // https://github.com/formkit/formkit/issues/1208
  if (node.props?.type === 'multi-step') {
    node.props.wrapperClass = '$remove:group';
    node.props.outerClass = '$remove:group';
  }
}

export default defineFormKitConfig(() => {
  const config = useRuntimeConfig();
  const pro = createProPlugin(config.public.FORMKIT_PRO_KEY, inputs);

  return {
    locales: { nb, en },
    locale: 'nb',
    iconLoaderUrl: (iconName) =>
      `https://cdn.jsdelivr.net/npm/heroicons@2.1.3/24/outline/${iconName}.svg`,
    config: {
      rootClasses,
    },
    icons: {
      ...applicationIcons,
      ...genesisIcons,
    },
    inputs: {
      datepicker: createInput(Datepicker, {
        props: ['timePicker', 'valueFormat', 'todayInitial'],
      }),
      autocompletev2: createInput(InputAutocomplete, {
        props: [
          'options',
          'multiselect',
          'optionLoader',
          'optionsLoader',
          'description',
          'image',
          'showCreate',
          'onPopoverToggle',
          'onCreateNew',
          'placeholder',
          'createdValue',
        ],
      }),
    },
    plugins: [
      pro,
      createMultiStepPlugin(),
      addAsteriskPlugin,
      (node) => axosNodeDefaults(node),
      createAutoAnimatePlugin(
        {
          // Optional AutoAnimate config
          duration: 250,
          easing: 'ease-in-out',
        },
        {
          // Optional animation targets object
          global: ['outer'],
          // form: ['form'], // disabled due to conflict with FormInline
          repeater: ['items'],
        },
      ),
      preventAutoFill,
    ],
    props: {
      popover: true,
    },
  };
});
