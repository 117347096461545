
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as invalid_45tokenRVleQvVxL5Meta } from "/app/pages/invalid-token.vue?macro=true";
import { default as meQzFFSRzs0uMeta } from "/app/pages/me.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "invalid-token",
    path: "/invalid-token",
    component: () => import("/app/pages/invalid-token.vue")
  },
  {
    name: "me",
    path: "/me",
    component: () => import("/app/pages/me.vue")
  }
]