import type { NuxtError } from '#app';

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

interface Toast {
  id: string;
  message: string;
  type: ToastType;
  timeout?: number;
}

export default function () {
  const toasts = useState<Toast[]>('appToasts', () => []);

  function showToast(
    message: string,
    type: ToastType = ToastType.SUCCESS,
    timeout = 5000,
  ) {
    const id = Math.random().toString(36).substr(2, 9);
    const localizedMessage = message;
    toasts.value.push({ id, message: localizedMessage, type, timeout });
    setTimeout(() => removeToast(id), timeout);
  }

  function removeToast(id: string) {
    toasts.value = toasts.value.filter((toast) => toast.id !== id);
  }

  function showSuccess(message: string, timeout?: number) {
    showToast(message, ToastType.SUCCESS, timeout);
  }

  function showError(error: NuxtError | string | any, timeout: number = 10000) {
    if (typeof error === 'string') {
      showToast(error, ToastType.ERROR, timeout);
    } else {
      showToast(
        error?.resourceId ?? error?.message ?? 'En feil har oppstått',
        ToastType.ERROR,
        timeout,
      );
    }
  }

  function showWarning(message: string, timeout?: number) {
    showToast(message, ToastType.WARNING, timeout);
  }

  function showInfo(message: string, timeout?: number) {
    showToast(message, ToastType.INFO, timeout);
  }

  return {
    toasts,
    show: showToast,
    remove: removeToast,
    success: showSuccess,
    $success: showSuccess,
    error: showError,
    $error: showError,
    warning: showWarning,
    $warning: showWarning,
    info: showInfo,
    $info: showInfo,
  };
}
