<template>
  <header
    class="flex w-full items-center justify-center bg-primary p-5"
    :style="{ backgroundColor: organisation?.primaryColorCode ?? '#0059ff' }"
  >
    <img
      :src="organisation?.logo ?? '/axos-logo-negative.svg'"
      alt="Logo"
      class="h-10 w-auto"
    />
  </header>
</template>

<script setup lang="ts">
const { organisation } = useExternalOrganisation();
</script>
